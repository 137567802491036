<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改消息' : '添加消息'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    width="850px"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="通知目标：" prop="type">
        <el-radio-group v-model="modalData.type">
          <el-radio
            v-for="(item, index) in this.const.MESSAGE_TYPE"
            :label="item.value"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标题：" prop="title">
        <el-input
          v-model="modalData.title"
          placeholder="请输入消息标题"
          :maxLength="50"
          double
        ></el-input>
      </el-form-item>

      <el-form-item label="发布时间：" prop="playTime">
        <el-date-picker
          v-model="modalData.playTime"
          @change="changeTime"
          type="datetime"
          placeholder="请选择发布时间"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="跳转URL：" prop="url">
        <el-input
          v-model="modalData.url"
          placeholder="跳转url"
        ></el-input>
      </el-form-item>

      <el-form-item label="内容：" prop="content">
        <!-- <el-input
          type="textarea"
          v-model="modalData.content"
          placeholder="请输入消息内容"
          double
        ></el-input> -->
        <Editor ref="editor"></Editor>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import Editor from "@/components/editor";

export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  components: { Editor },
  data() {
    return {
      isShowNum: false, 
      formName: "form",
      ruleValidate: {
        type: [
          {
            required: true,
            message: "请选择通知目标",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入消息标题",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入消息内容",
          },
        ],
      },
    };
  },
  methods: {
    showModal() {
      this.isShow = true;
      this.$nextTick(() => {
        this.$refs.editor.setContent(this.modalData.content || '');
      });
    },
    closeModal() {
      this.isShow = false;
      setTimeout(() => {
        this.$refs.editor.setContent("");
        if (this.$refs[this.formName]) {
          this.$refs[this.formName].clearValidate();
          //  this.$refs[this.formName].resetFields();
        }
      }, 200);
    },
    changeTime(e) {
      this.modalData.playTime = this.$filter.dateFormat(e);
    },
    submit() {
      this.modalData.content = this.$refs.editor.getContent();
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
