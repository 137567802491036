<template>
  <div style="line-height: initial">
    <el-upload
      v-show="false"
      id="upload"
      ref="upload"
      :action="baseUrl + version + '/FileUpload'"
      :headers="{ Authorization: 'Bearer ' + token }"
      :on-success="fileSuccess"
    ></el-upload>

    <quill-editor
      :options="editorOption"
      ref="quill"
      :content="content"
      @change="onEditorChange($event)"
    >
    </quill-editor>
  </div>
</template> 

<script>
import { baseUrl, downloadUrl, version } from "@/config/env";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
Quill.register("modules/resizeImage ", resizeImage);
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
//import { ImageDrop } from 'quill-image-drop-module'; // 拖动加载图片组件。
//   Quill.register('modules/imageDrop', ImageDrop);

export default {
  components: { QuillEditor },

  data() {
    const toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image", "video", "file"],
      ["clean"], // remove formatting button
    ];
    let token = this.$store.getters.access_token;
    return {
      baseUrl: baseUrl,
      version: "/v1",
      imgUrl: downloadUrl,
      token: token,
      content: "",
      editorOption: {
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: (value) => {
                console.log(value, "handers");
                if (value) {
                  this.$refs.upload.$el
                    .getElementsByClassName("el-upload__input")[0]
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
    };
  },

  methods: {
    onEditorChange(e) {
      console.log(",change", e);
    },

    /**
     * 1、文件上传成功保存文件列表
     * 2、提交的时候校验列表中的文件是否有用到，如果没有用到请求后台接口删除这些文件
     */
    fileSuccess(res) {
      let editor = this.$refs.quill;
      let quill = editor.getQuill();
      let range = quill.getSelection();
      let imgs = ["jpg", "gif", "png", "jpeg", "ico"];
      let type = "";
      
      if (imgs.indexOf(res.data.fileExt > -1)) {
        //如果上传的是图片
        console.log(this.imgUrl + res.data.path,'this.imgUrl + res.data.path');
        quill.insertEmbed(range.index, "image", this.imgUrl + res.data.path);
        type = "img";
      } else {
        this.$alert(
          <div>
            <p>请手动添加附件地址：</p>
            <p class="wb">{this.imgUrl + res.data.path}</p>
          </div>,
          "提示"
        );
        type = "file";
      }
      quill.setSelection(range.index + 1);

      // this.$emit('uploadFile',res.data.filePath,type)
    },

    clearContent() {
      this.$refs.quill.quill.root.innerHTML = "";
    },

    setContent(content) {
      this.$refs.quill.setHTML(content);
    },

    getContent() {
      return this.$refs.quill.getHTML();
    },
  },
};
</script>

<style lang="less">
.ql-editor {
  min-height: 300px;
}
</style>
